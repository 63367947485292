import classNames from "classnames";
import React from "react";
import { Link as RouterLink, LinkProps } from "react-router";
import { useStyles } from "./styles";

interface IProps {
	noDecoration?: boolean;
	external?: boolean;
}

export const Link: FC<LinkProps & IProps> = ({
	className,
	noDecoration,
	external,
	children,
	innerRef,
	...linkProps
}) => {
	const classes = useStyles();
	const isExternal = external || (linkProps.to && typeof linkProps.to === "string" && linkProps.to.startsWith("http"));
	const props = {
		className: classNames(className, {
			[classes.noDecoration]: noDecoration
		}),
		...linkProps
	};

	const href = typeof linkProps.to === "string" ? linkProps.to : `${linkProps.to.pathname}${linkProps.to.search}`;
	return isExternal ? (
		<a href={href} ref={innerRef} {...props}>
			{children}
		</a>
	) : (
		<RouterLink ref={innerRef} {...props}>
			{children}
		</RouterLink>
	);
};
